<template>
  <div class="nhs-profile-page col-10 offset-1">
    <CCard>
      <CCardBody>
        <div class="header-banner mb-1">
          <img
            src="/img/tfw/profile-page/nhs-banner.png"
            alt="banner"
            width="100%"
            class="img-banner"
          />
        </div>
        <div class="tabs">
          <CButton class="btn-nhs">About Us</CButton>
          <CButton class="btn-nhs">Locations</CButton>
          <CButton class="btn-nhs">Conditions & Treatment</CButton>
          <CButton class="btn-nhs">Innovative</CButton>
          <CButton class="btn-nhs" to="/tfw/job-board/26">Jobs</CButton>
        </div>
        <div class="content mt-2">
          <img
            src="/img/tfw/profile-page/nhs-content.png"
            alt="top"
            width="100%"
            class="img-banner"
          />
          <h3 class="nhs-title px-1">
            General Children's Hospital: Nurturing Health, Inspiring Hope
          </h3>
          <!-- <h3 class="nhs-title">Medical English Provider</h3> -->
          <div class="text-content">
            <div class="row">
              <div class="col-6">
                <p class="text-nhs text-justify">
                  At GCH, we are dedicated to providing exceptional healthcare
                  for children and young people. Our commitment revolves around
                  nurturing health, inspiring hope, and ensuring the well-being
                  Of the youngest members Of our community.
                </p>
                <h6 class="text-nhs nhs-subtitle">About Us</h6>
                <ul class="mb-2">
                  <li>
                    <p class="text-justify fs-16">
                      A Legacy Of Care: With a rich history dating back to 1920,
                      GCH has been at the forefront Of pediatric care,
                      consistently evolving to meet the changing needs Of our
                      patients.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Comprehensive Services: We Offer a wide range Of medical
                      services, from routine check-ups to specialized
                      treatments. all delivered with a focus on compassion and
                      excellence.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      World-Class Team: Our dedicated team Of healthcare
                      professionals includes leading specialists, nurses. and
                      support staff. all united in the mission to provide the
                      highest standard Of care.
                    </p>
                  </li>
                </ul>
                <h6 class="text-nhs nhs-subtitle">Research and Innovation</h6>
                <ul class="mb-2">
                  <li class="text-justify fs-16">
                    <p>
                      Pioneering Research: As a leading pediatric research
                      center. GCH contributes to groundbreaking medical
                      advancements, ensuring that our patients benefit from the
                      latest innovations in healthcare.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="col-6">
                <div class="bg-white p-3 mb-1 col-10 mx-auto">
                  <div class="text-center">
                    <CButton shape="pill" class="btn-explore text-center"
                      >Support Our Mission</CButton
                    >
                  </div>
                  <p class="text-nhs-black fs-16 text-center">
                    Learn how you can contribute to our mission Of providing
                    world-class healthcare to children. Whether through
                    donations, volunteering. or fundraising, your support makes
                    a difference.
                  </p>
                </div>
                <h6 class="text-nhs nhs-subtitle">Our Facilities</h6>
                <ul>
                  <li>
                    <p class="text-justify fs-16">
                      State-of-the-Art Hospital: Explore our modern.
                      purpose-built facilities designed to create a healing
                      environment that prioritizes the comfort and well- being
                      Of our young patients.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Cutting-edge Technology: GCH is equipped with the latest
                      medical technology to ensure accurate diagnostics and
                      advanced treatments.
                    </p>
                  </li>
                </ul>
                <h6 class="text-nhs nhs-subtitle">
                  Patient and Family Support
                </h6>
                <ul>
                  <li>
                    <p class="text-justify fs-16">
                      Holistic Approach: We understand that caring for children
                      goes beyond medical treatment. Our holistic approach
                      considers the emotional and psychological well-being Of
                      patients and their families.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Family-Focused Care: GCH actively involves families in the
                      care process. providing support and resources to ensure a
                      collaborative and nurturing environment.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-banner">
          <img
            src="/img/tfw/profile-page/nhs-footer.png"
            class="img-footer"
            alt="footer"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.nhs-profile-page {
  font-family: cursive !important;
}
.img-banner {
  max-height: 320px;
}
.img-footer {
  width: 100%;
}
.btn-nhs {
  background-color: #c2e298;
  color: #fff;
  width: 160px;
  margin-right: 2px;
  padding: 5px;
  font-size: 10px;
}
.btn-explore {
  background-color: #d91d5b;
  color: #fff;
}
.nhs-title {
  color: #1d3787;
  font-weight: 700;
}
.text-nhs {
  color: #1d3787;
  font-size: 16px;
}
.text-nhs-black {
  color: #111212;
}

.fs-16 {
  font-size: 16px;
}

.nhs-subtitle {
  font-weight: 600;
  font-size: 18px;
}
.bg-nhs {
  background-color: #edeeee;
}
.content {
  background-color: #e1ecf8;
}
</style>
