<template>
  <div class="slc-profile-page col-10 offset-1">
    <CCard>
      <CCardBody>
        <div class="header-banner mb-1">
          <img
            src="/img/tfw/profile-page/slc-header.png"
            alt="banner"
            width="100%"
            class="img-banner"
          />
        </div>
        <div class="tabs">
          <CButton class="btn-slc">About Us</CButton>
          <CButton class="btn-slc">OET Preparation</CButton>
          <CButton class="btn-slc">IELTS & PTE</CButton>
          <CButton class="btn-slc">Medical English</CButton>
          <CButton class="btn-slc" @click="openCourseList">Courses</CButton>
        </div>
        <div class="content mt-2">
          <h3 class="slc-title">
            Specialist Language Courses: The UK's Leading Medical English
            Provider
          </h3>
          <!-- <h3 class="slc-title">Medical English Provider</h3> -->
          <div class="text-content">
            <div class="row">
              <div class="col-6">
                <p class="text-slc text-justify">
                  Discover a world of linguistic possibilities with Specialist
                  Language Courses. Our platform is designed to empower language
                  enthusiasts, professionals, and curious minds alike to embark
                  on a journey of language mastery. Whether you're a beginner or
                  looking to refine your language skills, we have tailored
                  courses to meet your specific needs.
                </p>
                <h6 class="text-slc slc-subtitle">What Sets Us Apart</h6>
                <ul>
                  <li>
                    <p class="text-justify fs-16">
                      Diverse Language Offerings: Explore a wide array of
                      language courses, from popular languages to specialized
                      dialects. Our curriculum is designed to cater to various
                      proficiency levels.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Experienced Instructors: Learn from industry experts and
                      experienced language instructors who are passionate about
                      helping you achieve fluency.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Interactive Learning: Immerse yourself in an interactive
                      learning experience, combining traditional methods with
                      modern technology to make language acquisition enjoyable
                      and effective.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="col-6">
                <div class="bg-slc p-3 mb-1">
                  <h6 class="text-slc slc-subtitle">Get Started Today</h6>
                  <p class="text-slc-black fs-16">
                    Ready to embark on your language-learning adventure? Explore
                    our courses, meet our instructors, and take the first step
                    towards unlocking a world of opportunities through language.
                  </p>
                  <div class="text-center">
                    <CButton shape="pill" class="btn-explore text-center"
                      >Explore Now</CButton
                    >
                  </div>
                </div>
                <h6 class="text-slc slc-subtitle">Key Features:</h6>
                <ol start="1" type="1">
                  <li>
                    <p class="text-justify fs-16">
                      Comprehensive Courses: Our courses cover speaking,
                      listening, reading, and writing skills, ensuring a
                      holistic language learning experience.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Flexible Scheduling: Choose from flexible schedules to fit
                      your lifestyle. Learn at your own ace without compromising
                      on the quality of education.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Cutting-edge Resources: Access a wealth o resources,
                      including multimedia content, quizzes, and interactive
                      exercises, to enhance your learning journey.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-banner">
          <img
            src="/img/tfw/profile-page/slc-footer.png"
            class="img-footer"
            alt="footer"
          />
        </div>
      </CCardBody>
    </CCard>
    <div v-if="isShowCourseModal" class="modal" id="course-model">
      <div class="modal-content">
        <CourseList
          class="pt-5"
          @modalCallBack="courseModalCallback"
          @closeCourseList="closeCourseList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CourseList from "@/containers/TFW/LandingPage/StaticCourseList";
export default {
  components:{
    CourseList
  },
  data() {
    return {
      isShowCourseModal: false,
    };
  },
  methods: {
    openCourseList(){
      this.isShowCourseModal = true;
    },
    courseModalCallback() {
      this.isShowCourseModal = false;
    },
    closeCourseList(payload) {
      this.courseModalCallback();
      if (payload) this.$router.push("/tfw/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.img-banner {
  max-height: 320px;
}
.img-footer {
  width: 100%;
}
.btn-slc {
  background-color: #1d3787;
  color: #fff;
  width: 180px;
  margin-right: 2px;
  padding: 5px;
}
.btn-explore {
  background-color: #00b9de;
  color: #fff;
}
.slc-title {
  color: #1d3787;
  font-weight: 700;
}
.text-slc {
  color: #1d3787;
  font-size: 16px;
}
.text-slc-black {
  color: #111212;
}

.fs-16 {
  font-size: 16px;
}

.slc-subtitle {
  font-weight: 600;
  font-size: 18px;
}
.bg-slc {
  background-color: #edeeee;
}


.modal {
  display: block;
  position: fixed;
  z-index: 1200;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.modal-content img {
  margin: auto;
  display: block;
  width: 100%;
  max-width: 700px;
}

.modal-content {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

.modal-content {
  color: unset;
  max-width: 100%;
  background-color: unset;
}
.tfw-course-list .card {
  min-height: 280px;
}
.course-footer {
  bottom: 5px;
}
.bg-course .modal-content {
  background-color: #fff !important;
}

@media screen and (max-width: 767px) {
  .tfw-course-list .card {
    min-height: 330px;
  }
}

.close {
  top: 10px !important;
}
</style>
