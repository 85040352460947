<template>
  <div class="doh-profile-page col-10 offset-1">
    <CCard>
      <CCardBody>
        <div class="header-banner mb-1">
          <img
            src="/img/tfw/profile-page/doh-banner.png"
            alt="banner"
            width="100%"
            class="img-banner"
          />
        </div>
        <div class="tabs">
          <CButton class="btn-doh btn-doh-1">About DOH</CButton>
          <CButton class="btn-doh btn-doh-2">Initiatives & Programs</CButton>
          <CButton class="btn-doh btn-doh-3">Services</CButton>
          <CButton class="btn-doh btn-doh-4">Investors</CButton>
          <CButton class="btn-doh btn-doh-4" to="/tfw/job-board/26">Jobs</CButton>
        </div>
        <div class="content mt-2">
          <h3 class="doh-title">Welcome to the Department of Health (DOH)</h3>
          <div class="text-content">
            <div class="row">
              <div class="col-6">
                <p class="text-doh doh-subtitle text-justify">
                  Discover a wealth of healthcare information and resources at
                  the official website of the Department of Health, United Arab
                  Emirates. We are committed to promoting the well- being of the
                  community by providing comprehensive healthcare services and
                  valuable health-related information.
                </p>
                <h6 class="text-doh doh-subtitle">About DOH</h6>
                <ul>
                  <li>
                    <p class="text-justify fs-16">
                      Mission: The DOH is dedicated to enhancing the health and
                      well-being of the people of the United Arab Emirates
                      through innovative and accessible healthcare services.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Vision: Striving to be a leading global health authority,
                      the DOH aims to create a healthier and happier society.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Comprehensive Services: Explore the diverse range of
                      healthcare services and initiatives aimed at ensuring the
                      highest standards of health for residents and visitors.
                    </p>
                  </li>
                </ul>
                <h6 class="text-doh doh-subtitle">Key Areas of Focus</h6>
                <ol type="1">
                  <li>
                    <p class="text-justify fs-16">
                      Public Health: Learn about public health programs and
                      initiatives aimed at preventing diseases, promoting
                      healthy lifestyles, and ensuring the overall well-being of
                      the community.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Healthcare Facilities: Access information about healthcare
                      facilities, hospitals, and clinics operated nd regulated
                      by the DOH, ensuring quality care or all.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Health Regulations: Stay informed about health
                      regulations, policies. and guidelines implemented by the
                      DOH to maintain and improve the health standards across
                      the region.
                    </p>
                  </li>
                </ol>
              </div>
              <div class="col-6">
                <div class="bg-doh py-3 px-4 mb-3 mx-auto">
                  <h6 class="text-doh-1 doh-subtitle mb-2">
                    Programs & Campaigns
                  </h6>
                  <ul>
                    <li>
                      <h6 class="text-doh-2 doh-subtitle mb-2">Donation</h6>
                    </li>
                    <li>
                      <h6 class="text-doh-3 doh-subtitle mb-2">Education</h6>
                    </li>
                    <li>
                      <h6 class="text-doh-4 doh-subtitle mb-2">Support</h6>
                    </li>
                    <li>
                      <h6 class="text-doh-5 doh-subtitle mb-2">Excellence</h6>
                    </li>
                    <li>
                      <h6 class="text-doh-6 doh-subtitle mb-2">Research</h6>
                    </li>
                  </ul>
                </div>
                <h6 class="text-doh doh-subtitle">Initiatives and Campaigns</h6>
                <ul class="mb-3">
                  <li>
                    <p class="text-justify fs-16">
                      Community Outreach: Discover ongoing initiatives and
                      campaigns focused on raising awareness, educating the
                      public, and actively engaging communities in
                      health-related activities.
                    </p>
                  </li>
                  <li>
                    <p class="text-justify fs-16">
                      Preventive Healthcare: Learn about preventive healthcare
                      programs designed to identify health risks early,
                      promoting a proactive approach to well-being.
                    </p>
                  </li>
                </ul>
                <h6 class="text-doh doh-subtitle">Initiatives and Campaigns</h6>
                <ul>
                  <li>
                    <p class="text-justify fs-16">
                      Health Insights: Access a wealth of healthcare resources,
                      publications, and research materials to stay informed
                      about the latest developments in the field of health
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-banner">
          <img
            src="/img/tfw/profile-page/doh-footer.png"
            class="img-footer"
            alt="footer"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.img-banner {
  max-height: 320px;
}
.img-footer {
  width: 100%;
}
.btn-doh {
  color: #fff;
  width: 190px;
  margin-right: 2px;
  padding: 5px;
}
.btn-doh-1 {
  background-color: #0b5e67;
}
.btn-doh-2 {
  background-color: #47858c;
}
.btn-doh-3 {
  background-color: #80abb0;
}
.btn-doh-4 {
  background-color: #bcd3d5;
}
.btn-explore {
  background-color: #00b9de;
  color: #fff;
}
.doh-title {
  color: #000;
  font-weight: 700;
}
.text-doh {
  color: #135f69;
  font-size: 16px;
}
.text-doh-1 {
  color: #f6f4f5;
  font-size: 16px;
}
.text-doh-2 {
  color: #afcdca;
  font-size: 16px;
}
.text-doh-3 {
  color: #b6d3d5;
  font-size: 16px;
}
.text-doh-4 {
  color: #b8d1cf;
  font-size: 16px;
}
.text-doh-5 {
  color: #bed1cb;
  font-size: 16px;
}
.text-doh-6 {
  color: #aacfca;
  font-size: 16px;
}
.text-doh-7 {
  color: #a2ced4;
  font-size: 16px;
}
.text-doh-black {
  color: #111212;
}

.fs-16 {
  font-size: 16px;
}

.doh-subtitle {
  font-weight: 600;
  font-size: 18px;
}
.bg-doh {
  max-width: 70%;
  background-image: linear-gradient(to right, #1e7780, #1e686f);
}
</style>
